<template>
  <div class="main-container" v-if="dataReady">
      <div class="user-info-container">
        <h1>Mein Profil</h1>
        <div class="user-name">Username: {{ this.user.username }}</div>
        <div class="email">Email: {{ this.user.email }}</div>
      </div>
        <div class="results-container" v-if="true">
        <h1>Meine Daten</h1>
        <p>
          Hier sehen Sie Ihre persönlichen Daten zu den Aufgaben aus den
          Experimentiermodulen. Sie können diese jederzeit downloaden um diese
          im Detail anzusehen und auf Ihre eigene Art und Weise auswerten.
          Vielleicht können Sie bei Ihrer eigenen Analyse Ihre Fehler in den
          Aufgaben so besser nachvollziehen.
        </p>

        <h2>Sortieren mit System</h2>
          <h3>Teil 1</h3>
          <input
            id="sorting-assign1-results"
            type="text"
            class="form-control"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-sm"
            readonly
            style="background-color: #e0e0e0"
          />
          <h3>Teil 2</h3>
          <input
            id="sorting-assign2-results"
            type="text"
            class="form-control"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-sm"
            readonly
            style="background-color: #e0e0e0"
          />
        <div class="btn-container-right">
          <button class="custom-btn" @click="getMyResultsSorting()">
            Ergebnisse abrufen
          </button>          
        </div>

        <h2>Mit Sicherheit</h2>

          <h3>Teil 1</h3>
          <input
            id="security-assign1-results"
            type="text"
            class="form-control"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-sm"
            readonly
            style="background-color: #e0e0e0"
          />
          <h3>Teil 2</h3>
          <input
            id="security-assign2-results"
            type="text"
            class="form-control"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-sm"
            readonly
            style="background-color: #e0e0e0"
          />
          <div class="btn-container-right">
          <button class="custom-btn" @click="getMyResultsSecurity()">
            Ergebnisse abrufen
          </button>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: "UserProfile",
  components: {},
  emits: [],
  mounted(){
    this.dataReady = true;
  }, 
  data() {
    return {
      dataReady: false,
      user: {},
      myResultsA1: {},
      myResultsA2: {},
    };
  },
  methods: {
    async getMyResultsSorting() {
      try {
        const response = await fetch(
          process.env.VUE_APP_STRAPI_API_URL +
            "/api/inf-sorting-a1-log/myResults",
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("jwt")}`,
            },
          }
        );
        const res = await response.json();
        if (!res || res.length == 0) {
          this.myResultsA1 = "NO RESULTS FOUND";
        } else if (res.length > 1) {
          console.error("THERE ARE SEVERAL RESULTS FOR THIS USER");
          this.myResultsA1 = res;
        } else {
          this.myResultsA1 = res;
        }
      } catch (error) {
        console.log(error);
      }

      try {
        const response = await fetch(
          process.env.VUE_APP_STRAPI_API_URL +
            "/api/inf-sorting-a2-log/myResults",
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("jwt")}`,
            },
          }
        );
        const res = await response.json();
        if (!res || res.length == 0) {
          this.myResultsA2 = "NO RESULTS FOUND";
        } else if (res.length > 1) {
          console.error("THERE ARE SEVERAL RESULTS FOR THIS USER");
          this.myResultsA2 = res;
        } else {
          this.myResultsA2 = res;
        }
      } catch (error) {
        console.log(error);
      }
      this.loadResultsIntoInputSorting();
    },
    loadResultsIntoInputSorting() {
      document.getElementById("sorting-assign1-results").value = JSON.stringify(
        this.myResultsA1
      );
      document.getElementById("sorting-assign2-results").value = JSON.stringify(
        this.myResultsA2
      );
    },
    async getMyResultsSecurity() {
      try {
        const response = await fetch(
          process.env.VUE_APP_STRAPI_API_URL +
            "/api/inf-sorting-a1-log/myResults",
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("jwt")}`,
            },
          }
        );
        const res = await response.json();
        if (!res || res.length == 0) {
          this.myResultsA1 = "NO RESULTS FOUND";
        } else if (res.length > 1) {
          console.error("THERE ARE SEVERAL RESULTS FOR THIS USER");
          this.myResultsA1 = res;
        } else {
          this.myResultsA1 = res;
        }
      } catch (error) {
        console.log(error);
      }

      try {
        const response = await fetch(
          process.env.VUE_APP_STRAPI_API_URL +
            "/api/inf-sorting-a2-log/myResults",
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("jwt")}`,
            },
          }
        );
        const res = await response.json();
        if (!res || res.length == 0) {
          this.myResultsA2 = "NO RESULTS FOUND";
        } else if (res.length > 1) {
          console.error("THERE ARE SEVERAL RESULTS FOR THIS USER");
          this.myResultsA2 = res;
        } else {
          this.myResultsA2 = res;
        }
      } catch (error) {
        console.log(error);
      }
      this.loadResultsIntoInputSecurity();
    },
    loadResultsIntoInputSecurity() {
      document.getElementById("security-assign1-results").value =
        JSON.stringify(this.myResultsA1);
      document.getElementById("security-assign2-results").value =
        JSON.stringify(this.myResultsA2);
    },
  },
};
</script>

<style scoped>
.btn-container-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-self: flex-end;
}
</style>
